import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
// GeneralViews
import NotFound from "@/pages/NotFoundPage.vue";

// Admin pages
const Dashboard = () =>
  import(/* webpackChunkName: "dashboard" */ "@/pages/Dashboard.vue");

const Profile = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Profile.vue");

const Users = () => 
  import("@/pages/Users.vue");

const OrderReal = () => 
  import("@/pages/OrderReal.vue");

const OrderDemo = () => 
  import("@/pages/OrderDemo.vue");

const Deposit = () => 
  import("@/pages/Deposit.vue");

const Withdrawal = () => 
  import("@/pages/Withdrawal.vue");

const Login = () => 
  import("@/pages/Login.vue");

const Settings = () => 
  import("@/pages/Settings.vue");

const Promote = () =>
  import("@/pages/Promote.vue");

const routes = [
  {
    path: "/",
    component: Login,
    name: "Login"
  },
  {
    path: "/main",
    component: DashboardLayout,
    redirect: "/dashboard",
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: Dashboard,
      },
      {
        path: "users",
        name: "users",
        component: Users
      },
      {
        path: "order-real",
        name: "Orders Real",
        component: OrderReal
      },
      {
        path: "order-demo",
        name: "Orders Demo",
        component: OrderDemo
      },
      {
        path: "deposit",
        name: "Deposit",
        component: Deposit
      },
      {
        path: "withdrawal",
        name: "Withdrawal",
        component: Withdrawal
      },
      {
        path: "profile/:id",
        name: "profile",
        component: Profile,
      },
      {
        path: "settings",
        name: "settings",
        component: Settings
      },
      {
        path: "promote",
        name: "promote code",
        component: Promote
      }
    ],
  },
  { path: "*", component: NotFound },
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;
