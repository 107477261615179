<template>
  <div class="wrapper">
    <side-bar>
      <template slot="links">
        <sidebar-link
          to="/main/dashboard"
          :name="$t('sidebar.dashboard')"
          icon="tim-icons icon-chart-pie-36"
        />
        <sidebar-link
          to="/main/users"
          :name="'Users'"
          icon="tim-icons icon-badge"
        />
        <sidebar-link
          to="/main/order-real"
          :name="'Orders Real'"
          icon="tim-icons icon-chart-bar-32"
        />
        <sidebar-link
          to="/main/order-demo"
          :name="'Orders Demo'"
          icon="tim-icons icon-paper"
        />
        <sidebar-link
          to="/main/deposit"
          :name="'Deposits'"
          icon="tim-icons icon-coins"
        />
        <sidebar-link
          to="/main/withdrawal"
          :name="'Withdrawals'"
          icon="tim-icons icon-single-copy-04"
        />
        <sidebar-link
          to="/main/promote"
          :name="'Promote Code'"
          icon="tim-icons icon-single-copy-04"
        />
        <sidebar-link
          to="/main/settings"
          :name="'Settings'"
          icon="tim-icons icon-single-copy-04"
        />
        <!-- <sidebar-link
          to="/icons"
          :name="$t('sidebar.icons')"
          icon="tim-icons icon-atom"
        /> -->
        <!-- <sidebar-link
          to="/maps"
          :name="$t('sidebar.maps')"
          icon="tim-icons icon-pin"
        /> -->
        <!-- <sidebar-link
          to="/notifications"
          :name="$t('sidebar.notifications')"
          icon="tim-icons icon-bell-55"
        /> -->
        <!-- <sidebar-link
          to="/profile"
          :name="$t('sidebar.userProfile')"
          icon="tim-icons icon-single-02"
        /> -->
        <!-- <sidebar-link
          to="/table-list"
          :name="$t('sidebar.tableList')"
          icon="tim-icons icon-puzzle-10"
        /> -->
        <!-- <sidebar-link
          to="/typography"
          :name="$t('sidebar.typography')"
          icon="tim-icons icon-align-center"
        /> -->
        <!-- <sidebar-link
          to="/dashboard?enableRTL=true"
          :name="$t('sidebar.rtlSupport')"
          icon="tim-icons icon-world"
        /> -->
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar"> </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss"></style>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu";
export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
